// app/context/EnvContext.tsx
import { createContext, useContext, ReactNode } from 'react';

export type ClientEnv = {
  apiUrl: string;
  reCaptchaSiteKey: string;
  reCaptchaSiteKeyV2: string;
  emailValidationToken: string;
  addressValidationToken: string;
  salesforceWebToCaseOrgid: string;
  salesforceWebToCaseLink: string;
  salesforceWebToCaseSubtypeName: string;
  siteUrl: string;
  gtmId: string;
  env: string;
};

const defaultClientEnv: ClientEnv = {
  apiUrl: '',
  reCaptchaSiteKey: '',
  reCaptchaSiteKeyV2: '',
  emailValidationToken: '',
  addressValidationToken: '',
  salesforceWebToCaseOrgid: '',
  salesforceWebToCaseLink: '',
  salesforceWebToCaseSubtypeName: '',
  siteUrl: '',
  gtmId: '',
  env: '',
};

const ClientEnvContext = createContext<ClientEnv>(defaultClientEnv);

type ClientEnvProviderProps = {
  children: ReactNode;
  clientEnv: ClientEnv;
};

export function ClientEnvProvider({
  children,
  clientEnv,
}: ClientEnvProviderProps) {
  return (
    <ClientEnvContext.Provider value={clientEnv}>
      {children}
    </ClientEnvContext.Provider>
  );
}

export function useClientEnv() {
  return useContext(ClientEnvContext);
}
